@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

/*
================== 
INDEX CSS

POUR STRUCTURE CSS VOIR: https://stackoverflow.com/questions/44484907/index-css-vs-app-css-in-default-app-created-by-create-react-app-whats-the
q

Size:
max width: 1440px;
66em (1050px)
56.25em (900px)
43.75 (700px)
34.38em (550px)
==================
*/

:root {
  /* 4f4eff blue */
  /*  ff7164 orange */

  /*  
  original color
  pink ae67fa; 
  orange f49867
    --color-subtext-orange: #ff8a71;
  --color-subtext-blue: #71e5ff;
  */

  --font-family: "Manrope", sans-serif;

  --gradient-text: linear-gradient(62deg, #4f4eff 38%, #ff7164 62%);
  --gradient-bar: linear-gradient(62deg, #4f4eff 38%, #ff7164 62%);

  --color-bg: #040c18;
  --color-bg-light: #031b34;
  --color-bg-bubble: rgb(0, 40, 83);
  --color-blog: #042c54;
  --color-main-pink: #4f4eff;
  --color-main-orange: #ff7164;
  --color-main-white: #fff;
  --color-subtext-orange: #ff918c;
  --color-subtext-blue: #e3e9ff;
}

/*
================== 
APP CSS
==================
*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: var(--font-family);
  font-size: 1.8rem;
  line-height: 1.62;
  background: var(--color-bg);
  color: var(--color-main-white);
  font-weight: 400;
  overflow: hidden;
}

p {
  margin-bottom: 1.6rem;
}

header .bubble-bg {
  width: 80vw;
  height: 110vh;
  background-color: var(--color-bg-bubble);
  filter: blur(35rem);
  border-radius: 50%;
  position: absolute;
  top: -50vh;
  left: -50vw;
  z-index: -1;
}
.service.row .bubble-bg {
  width: 80vw;
  height: 110vh;
  background-color: var(--color-bg-bubble);
  filter: blur(35rem);
  border-radius: 50%;
  position: absolute;
  top: 30%;
  right: -65%;
  z-index: -1;
}
.service.reverse .bubble-bg {
  width: 80vw;
  height: 110vh;
  background-color: var(--color-bg-bubble);
  filter: blur(35rem);
  border-radius: 50%;
  position: absolute;
  top: 30%;
  left: -70%;
  z-index: -1;
}

.portfolio .bubble-bg {
  width: 80vw;
  height: 130vh;
  background-color: var(--color-bg-bubble);
  filter: blur(35rem);
  border-radius: 50%;
  position: absolute;
  top: 25%;
  right: -10%;
  z-index: -1;
}
.blog .bubble-bg {
  width: 80vw;
  height: 100%;
  background-color: var(--color-bg-bubble);
  filter: blur(35rem);
  border-radius: 50%;
  position: absolute;
  top: 0;
  z-index: -1;
}

main {
  max-width: 120rem;
  min-height: 100vh;
  margin: 0 auto;
}

a {
  color: unset;
  text-decoration: none;
  cursor: pointer;
}

.primary-title {
  font-weight: 800;
  font-size: 6.4rem;
  line-height: 1.26;
  letter-spacing: -0.04em;
  margin-bottom: 1.8rem;
}

.secondary-title {
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 1.26;
  letter-spacing: -0.04em;
  margin-bottom: 1.8rem;
}

.center {
  text-align: center;
}

*.active {
  border-bottom: 4.8px solid var(--color-main-orange);
}

.gradient__bg {
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 25%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 25%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 25%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 25%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 25%
  );
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*66em (1050px) 56.25em (900px) 43.75 (700px) 34.38em (550px) */
@media screen and (max-width: 66em) {
  .primary-title {
    font-size: 6.2rem;
  }
}

@media screen and (max-width: 56.25em) {
  .primary-title {
    font-size: 5.8rem;
  }
}

@media screen and (max-width: 43.75em) {
  .primary-title {
    font-size: 5.8rem;
  }
}

@media screen and (max-width: 34.38em) {
  .secondary-title {
    font-size: 4rem;
  }
  .section__padding {
    padding: 3rem 2rem;
  }
  .section__margin {
    margin: 0;
  }
  .primary-title {
    font-size: 5rem;
    margin-bottom: 3rem;
  }
}

/*
================== 
CSS NAVBAR 
==================
*/
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  padding: 1.6rem 4.8rem;
  position: relative;
  color: var(--color-main-white);
  font-family: inherit;
}

.header-logo {
  display: flex;
  align-items: center;
}
.header-img {
  height: 3.5rem;
  margin-right: 0.5rem;
}
.header-title {
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: -0.04rem;
}
.header-nav {
  display: flex;
  align-items: center;
}

.header-link {
  font-size: 1.8rem;
  margin-right: 4.8rem;
}

.header-btn {
  font-size: 1.8rem;
  padding: 0.8rem 3rem;
  color: #fff;
  background-color: var(--color-main-pink);
  border-radius: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.header-hamburger {
  display: none;
  cursor: pointer;
  height: 3.2rem;
  width: 3.2rem;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.line-1,
.line-2,
.line-3 {
  background: #ffffff;
  height: 3.8px;
  width: 3.2rem;
  border-radius: 4px;
  /*  transition-delay: 0.2s;
  transition: all 0.8s ease-out; */
}

@media screen and (max-width: 66em) {
  .header-links {
    display: none;
  }
  .header-hamburger {
    display: flex;
  }
  .header-hamburger * {
    box-shadow: 0 2px 10px 0 rgba(174, 103, 250, 1);
  }

  .header-hamburger.show > .line-1 {
    animation: animate-line-1 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
  }
  .header-hamburger.show > .line-3 {
    animation: animate-line-3 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
  }
  .header-hamburger.show > .line-2 {
    animation: animate-line-2 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
  }
  .header-hamburger.noshow > .line-1 {
    animation: animate-line-1-rev 0.7s ease-in-out;
  }
  .header-hamburger.noshow > .line-3 {
    animation: animate-line-3-rev 0.7s ease-in-out;
  }
  .header-hamburger.noshow > .line-2 {
    animation: animate-line-2-rev 0.7s ease-in-out;
  }

  @keyframes animate-line-1 {
    0% {
      transform: translate3d(0, 0, 0) rotate(0deg);
    }
    50% {
      transform: translate3d(0, 11px, 0) rotate(0);
    }
    100% {
      transform: translate3d(0, 11px, 0) rotate(45deg);
    }
  }
  @keyframes animate-line-2 {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(0);
      opacity: 0;
    }
  }
  @keyframes animate-line-3 {
    0% {
      transform: translate3d(0, 0, 0) rotate(0deg);
    }
    50% {
      transform: translate3d(0, -11px, 0) rotate(0);
    }
    100% {
      transform: translate3d(0, -11px, 0) rotate(135deg);
    }
  }

  @keyframes animate-line-1-rev {
    0% {
      transform: translate3d(0, 11px, 0) rotate(45deg);
    }
    50% {
      transform: translate3d(0, 11px, 0) rotate(0);
    }

    100% {
      transform: translate3d(0, 0, 0) rotate(0deg);
    }
  }
  @keyframes animate-line-2-rev {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes animate-line-3-rev {
    0% {
      transform: translate3d(0, -11px, 0) rotate(135deg);
    }
    50% {
      transform: translate3d(0, -11px, 0) rotate(0);
    }

    100% {
      transform: translate3d(0, 0, 0) rotate(0deg);
    }
  }

  .header-links,
  .header-links.noshow {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transform: translateX(0);
    z-index: 9999;
    position: absolute;
    top: 10rem;
    left: 0;
    padding: 10rem;
    background-color: var(--color-bg-light);
    width: 0;
    height: 0;
    transition: all 0.4s ease-in;
    transform: translateX(-100%);
  }
  .header-links.show {
    width: 100%;
    height: 100vh;
    transform: translateX(0);
  }

  .header-link,
  .header-btn {
    display: none;
    visibility: hidden;
    margin: 2rem;
    transition: all 0.4s ease-in;
  }

  .header-links.show .header-link,
  .header-links.show .header-link .header-btn {
    visibility: visible;
    display: block;
  }
  /*  .header-links.show .header-link {
    visibility: visible;
  }  */
}
@media screen and (max-width: 34.38em) {
  .header {
    height: 7rem;
    padding: 0 2rem;
  }

  .header-img {
    height: 3rem;
    margin-right: 0.5rem;
  }
  .header-title {
    font-size: 2rem;
  }
}

/*
================== 
COMPONENT HERO
==================
*/

.hero {
  display: flex;
  flex-direction: row;
  gap: 3.2rem;
}

.hero-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 66em) {
  .hero {
    flex-direction: column;
  }

  .hero-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 34.38em) {
}

/*
================== 
CONTAINER SERVICE 
==================
*/
.service-title {
  margin-bottom: 6rem;
}

.service {
  display: flex;
  gap: 3.2rem;
  position: relative;
}

.service.row {
  flex-direction: row;
}
.service.reverse {
  flex-direction: row-reverse;
}
.service-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.service-category {
  font-size: 2.8rem;
  font-weight: 800;
  margin-bottom: 1.8rem;
  letter-spacing: -0.04rem;
}
.service-category:before {
  content: "";
  position: absolute;
  width: 4.8rem;
  height: 0.5rem;
  background: var(--color-main-pink);
  margin-top: -1rem;
}
.service-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  aspect-ratio: 1/1;
}
.service-image img {
  width: 100%;
}

.service-techs {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 10rem;
}
.service-techs-dev {
  margin-bottom: 4rem;
}
.service-tech {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 10%;
  margin: 0.5rem 1rem;
}
.service-tech-bg {
  display: flex;
  width: 80%;
  background-color: var(--color-main-pink);
  box-shadow: rgba(174, 103, 250, 1) 0 0 2rem;
  border-radius: 100%;
}
.service-tech-bg img {
  width: 100%;
}
.service-tech-title {
  font-weight: 400;
  text-align: center;
}

/*
66em (1050px) 
56.25em (900px) 
43.75 (700px) 
34.38em (550px) 
*/

@media screen and (max-width: 66em) {
  .service.row,
  .service.reverse {
    flex-direction: column;
  }
}
@media screen and (max-width: 56.25em) {
  .service-tech {
    width: 12%;
  }
}
@media screen and (max-width: 43.75em) {
  .service-tech {
    width: 15%;
    margin: 0.5rem 3rem;
  }
  .social .service-tech {
    margin: 0.5rem 6rem;
  }
}
@media screen and (max-width: 34.38em) {
  .service-tech {
    width: 20%;
    margin: 0.5rem 2rem;
  }

  .service-tech-title {
    font-size: 1.6rem;
  }
  .social .service-tech {
    margin: 0.5rem 3.4rem;
  }
}
/*
================== 
COMPONENT CTA 
==================
*/
.cta {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  gap: 3rem;
  padding: 3.2rem;
  margin: 0 6rem 8rem 6rem;
  border-radius: 1rem;
  background: var(--gradient-bar);
}

.cta:last-of-type {
  margin-top: 4rem;
}

.cta-title {
  width: 62%;
  font-size: 3.2rem;
  line-height: 4.5rem;
  font-weight: 500;
  margin-left: 2rem;
}

.cta-btn {
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 1rem;
}

.cta-btn button {
  background: var(--color-bg);
  color: #fff;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 4.5rem;
  padding: 1rem 2rem;
  border-radius: 1rem;

  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
  width: 100%;
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
}

.cta-btn img {
  width: 12%;
}

/*
66em (1050px) 
56.25em (900px) 
43.75 (700px) 
34.38em (550px) 
*/

@media screen and (max-width: 56.25em) {
  .cta-btn {
    margin: 0;
  }
}

@media screen and (max-width: 43.75em) {
  .cta {
    flex-direction: column;
    margin: 0 2rem 8rem 2rem;
  }
  .cta-title {
    width: 100%;
    font-size: 3rem;
    line-height: 4rem;
    margin-left: 0;
  }
  .cta-btn {
    margin-top: 1.6rem;
    width: 100%;
  }
  .cta-btn button {
    width: 100%;
  }
  .cta-btn img {
    width: 14%;
  }
}
@media screen and (max-width: 43.75em) {
  .cta {
    padding: 3.2rem 1.6rem;
  }
}

/*
================== 
CONTAINER PORTFOLIO 
==================
*/

.portfolio {
  padding: 4rem 0;
  position: relative;
}

.portfolio-title {
  display: inline-block;
  margin-bottom: 4rem;
}
.portfolio-nav {
  width: 90%;
  margin: 0 auto 8rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 8rem 1.6rem;
}

.portfolio-carousel {
  overflow: hidden;
}
.portfolio-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: all 250ms linear;
}
.portfolio-item {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.portfolio-item-title {
  min-height: 4rem;
  font-size: 1.8rem;
}
.portfolio-item img {
  width: 90%;
  margin-bottom: 1rem;
}
.portfolio-showcase {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.portfolio-showcase img {
  width: 80%;
  margin-bottom: 2rem;
}

.portfolio-showcase-infos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6rem;
  align-items: flex-start;
  padding: 6rem;
  background: var(--color-bg-light);
  border-radius: 1rem;

  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.portfolio-showcase-content {
  width: 62%;
}
.portfolio-showcase-title {
  width: fit-content;
  font-size: 4.6rem;
}
.portfolio-showcase-subtitle {
  font-size: 3rem;
  line-height: 4rem;
}

.portfolio-showcase-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
}

.portfolio-btn {
  font-size: 2rem;
  font-weight: 600;
  padding: 0.8rem 3rem;
  margin: 2rem 0 4rem 0;
  border-radius: 5px;
  color: var(--color-bg-light);
  background-color: var(--color-main-orange);
}

.portfolio-showcase-tech * {
  font-size: 2.4rem;
  font-weight: 400;
  margin: 2.2rem 0;
}
.portfolio-showcase-tech *:before {
  content: "";
  position: absolute;
  width: 4.8rem;
  height: 0.5rem;
  background: var(--color-main-orange);
  margin-top: -1rem;
}

.portfolio-showcase-nav {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}
.portfolio-showcase-dot {
  height: 2rem;
  width: 2rem;
  margin-right: 2rem;
  border: solid 0.5rem white;
  border-radius: 50%;
  background-color: var(--color-main-orange);
  cursor: pointer;
}
.portfolio-showcase-dot.active {
  border: solid 1rem var(--color-main-orange);
}
/*
66em (1050px) 
56.25em (900px) 
43.75 (700px) 
34.38em (550px) 
*/

@media screen and (max-width: 56.25em) {
  .portfolio-showcase img {
    width: 100%;
    padding: 0 6rem;
    margin-bottom: 0;
  }
  .portfolio-showcase-infos {
    flex-direction: column;
    gap: 2rem;
  }
  .portfolio-showcase-content {
    width: 100%;
  }

  .portfolio-showcase-details {
    flex-direction: column-reverse;
    padding: 0;
  }
  .portfolio-btn {
    margin: 2rem 0;
  }
}
@media screen and (max-width: 43.75em) {
  .portfolio {
    padding: 0 2rem 4rem 2rem;
  }
  .portfolio-title {
    padding: 0;
  }

  .portfolio-item {
    flex: 0 0 100%;
  }
  .portfolio-showcase img {
    padding: 0 0 2rem 0;
  }
  .portfolio-showcase-nav {
    padding-top: 1rem;
  }
  .portfolio-showcase-infos {
    padding: 3rem 2rem;
    margin: 3rem 1rem 6rem 1rem;
  }
}
@media screen and (max-width: 34.38em) {
  .portfolio-showcase-title {
    font-size: 3rem;
    line-height: 4rem;
  }
  .portfolio-showcase-subtitle {
    font-size: 2.2rem;
    line-height: 3.5rem;
  }
}

/*
================== 
CONTAINERS BLOG 
==================
*/

.blog {
  display: flex;
  flex-direction: column;
  position: relative;
}

.blog-title {
  line-height: 8.4rem;
  width: fit-content;
  margin-bottom: 4rem;
}

.blog-container {
  display: flex;
  flex-direction: row;
}

.blog-container_groupA {
  width: 40%;
  margin-right: 2rem;
  background-color: var(--color-bg);
  > a {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg);
    > div.post-page-more__content {
      width: 100%;
      height: 100%;
      padding: 1.6rem;
    }
    > div.post-page-more__image {
      width: 100%;
    }
  }
}

.blog-container_groupB {
  width: 60%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  > a {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg);
    > div.post-page-more__content {
      width: 100%;
      height: 100%;
      padding: 1.6rem;
    }
    > div.post-page-more__image {
      width: 100%;
    }
  }
}

/*
66em (1050px) 
56.25em (900px) 
43.75 (700px) 
34.38em (550px) 
*/

@media screen and (max-width: 56.25em) {
  .blog-container {
    flex-direction: column;
  }

  .blog-container_groupA,
  .blog-container_groupB {
    width: 100%;
    margin: 2rem 0;
  }

  .blog-container_groupA .blog-container_article-image {
    height: 25rem;
  }
}

/*
66em (1050px) 
56.25em (900px) 
43.75 (700px) 
34.38em (550px) 
*/

@media screen and (max-width: 43.75em) {
  .blog-container_groupB {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 4rem;
  }
}

@media screen and (max-width: 34.38em) {
  .blog-container_groupB {
    grid-gap: 4rem;
  }
}

/*
================== 
COMPONENT ARTICLE 
==================
*/
/* .blog-container_article {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--color-bg-light);
}

.blog-container_article-image {
  width: 100%;
  height: 30%;
  overflow: hidden;
}

.blog-container_article-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-container_article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
  padding: 1rem 1.5rem;
}

.blog-container_article-content {
  font-family: var(--font-family);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
  color: #fff;
}
.blog-container-excerpt {
  margin-bottom: 1rem;
}
.blog-container_more {
  font-weight: 600;
}
.blog-container_more:after {
  content: ">>";
  font-weight: 900;
  letter-spacing: -0.5rem;
  color: var(--color-main-orange);
}

.blog-container-excerpt {
  font-size: 1.8rem;
}

.blog-container_article-content h3 {
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 3.5rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  cursor: pointer;
}

.blog-container_article-content p:last-child {
  cursor: pointer;
} */

/*
66em (1050px) 
56.25em (900px) 
43.75 (700px) 
34.38em (550px) 
*/
/* @media screen and (max-width: 56.25em) {
  .blog-container_more {
    margin-top: 3rem;
  }
} */
@media screen and (max-width: 43.75em) {
  .blog.section__padding {
    padding: 4rem 2rem;
  }
}

/*
================== 
COMPONENT FOOTER 
==================
*/

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem 6rem 2rem 6rem;
  background: var(--color-bg-light);
  margin-top: 10rem;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 3rem;
  width: 100%;
}

.footer-links_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links_logo img {
  height: 5rem;
  margin-bottom: 1rem;
}

.footer-copyright {
  font-size: 1.6rem;
  margin-top: 10rem;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
}

/*
66em (1050px) 
56.25em (900px) 
43.75 (700px) 
34.38em (550px) 
*/

@media screen and (max-width: 66em) {
  .footer-links {
    flex-direction: column;
  }
  .footer-links_logo {
    margin-bottom: 6rem;
  }
  .footer-copyright {
    margin-top: 6rem;
  }
}

/*
================== 
CREATE POST FORM
==================
*/
.create-post .primary-title {
  width: fit-content;
}
.create-post-form input {
  width: 100%;
  padding: 0.5rem;
  margin: 1rem 0;
}

.create-post-form * {
  background-color: white;
  color: var(--color-bg);
}

input[type="title"] {
  font-size: 2rem;
}
input[type="summary"] {
  font-size: 1.6rem;
}
input[type="file"] {
  padding: 0.5rem 0;
  background-color: var(--color-bg);
  color: white;
}
.quill {
  margin: 2rem 0 4rem 0;
}
.ql-container.ql-snow {
  min-height: 30rem;
}
.create-post-submit-btn {
  padding: 1rem 3rem;
  margin-top: 3rem;
}

/*
================== 
POST PAGE
==================
*/
.post-page {
  max-width: 75rem;
  margin: 6rem auto 10rem;
}
.post-page-time {
  display: block;
  margin-bottom: 3rem;
}
.post-page-image img {
  width: 100%;
  margin-bottom: 3rem;
}
.post-page-content {
  font-size: 2rem;
  line-height: 3.25rem;
}
.post-page-content h2 {
  font-size: 2.8rem;
  margin: 6rem 0 2rem;
}

.post-page-more {
  position: relative;
}
.post-page-more__title {
  width: fit-content;
  margin-bottom: 6rem;
}

.post-page-more__link {
  display: flex;
  flex-direction: row;
  margin: 6rem 0rem;
  gap: 1.6rem;
  background-color: var(--color-bg);
}

.post-page-more__image {
  width: 38%;
  > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}

.post-page-more__content {
  width: 62%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.6rem;
}

.post-page-more__title {
  margin-bottom: 1.6rem;
}

.post-page-more__cta {
  color: var(--color-main-orange);
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 1.6rem;
}

.post-page.post-page-more > .bubble-bg {
  width: 100vw;
  height: 100%;
  background-color: var(--color-bg-bubble);
  filter: blur(35rem);
  border-radius: 50%;
  position: absolute;
  top: 10%;
  left: -20%;
  z-index: -1;
}

.edit-btn {
  display: flex;
  width: 100%;
  height: 6rem;
  align-items: center;
  margin: 3rem;
  > svg {
    height: 6rem;
    margin-right: 1rem;
  }
}

.loader {
  border: 10px solid var(--color-main-orange);
  border-top: 10px solid var(--color-bg-light);
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  animation: spin 1s linear infinite;
  margin: 3rem auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*
66em (1050px) 
56.25em (900px) 
43.75 (700px) 
34.38em (550px) 
*/

@media screen and (max-width: 56.25em) {
  .post-page {
    padding: 0 4.8rem;
  }
  .post-page .cta {
    flex-direction: column;
  }
  .post-page-more__image,
  .post-page-more__content {
    width: 100%;
  }
  .post-page-more__excerpt {
    margin-bottom: 1.6rem;
  }
}
@media screen and (max-width: 34.38em) {
  .post-page {
    padding: 0 1.6rem;
  }
  .post-page-more__link {
    flex-direction: column;
  }
}

.login-form,
.register-form {
  max-width: 20rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  & > h1 {
    margin-bottom: 2rem;
  }
  & > input {
    margin-bottom: 2rem;
  }
}
